import React from 'react'

interface Props {
  fontsize?: string
  className?: string
  wobble?: boolean
}

const ArrowIcon = ({ fontsize = '3rem', className = '', wobble = true }: Props) => {
  const fontSizeNumber = fontsize.replace(/\D+$/g, '')
  const fontSizeUnit = fontsize.replace(fontSizeNumber, '')
  const marginFix = `-${Number(fontSizeNumber) * 0.055}${fontSizeUnit}`
  return (
    <>
      <span className={`wrap an-wobble-horizontal ${className}`}>
        <span className="">
          <i className="si-hyphen" />
          <i className="si-greater" />
        </span>
      </span>

      <style jsx>{`
        .wrap {
          //overflow: hidden;
        }
        i::before {
          width: 0.27em;
          font-size: ${fontsize};
        }
        .si-hyphen {
          margin-right: ${marginFix};
        }
      `}</style>
    </>
  )
}

export default ArrowIcon
