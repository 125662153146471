import { useTranslation } from 'next-i18next'
import { useAppDispatch, useAppSelector } from '@/app/hooks'
import { loadBestsellers, selectBestsellers } from '@/features/bestsellers'
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
const TravelSliderBanner = dynamic(() => import('@/components/models/travel/sliders/TravelSliderBanner'), { ssr: false })

const BestsellerSliderBlock = () => {
  const { t } = useTranslation('common')
  const dispatch = useAppDispatch()
  const bestsellerState = useAppSelector(selectBestsellers)
  const [domLoaded, setDomLoaded] = useState(false)

  useEffect(() => {
    setDomLoaded(true)
    if (!bestsellerState.isLoading) {
      dispatch(loadBestsellers({}))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>{domLoaded && <TravelSliderBanner title={t('bestseller')} travels={bestsellerState.travels} />}</>
}

export default BestsellerSliderBlock
